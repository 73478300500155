/** @jsx jsx */
import { jsx } from "theme-ui";
import DLWLayout from "../../../layouts/dlw-fixed";

import SpecialJournal from "../../../components/Trainer/SpecialJournal";

function SpecialExercise() {
  return (
    <DLWLayout>
      <SpecialJournal />
    </DLWLayout>
  );
}

export default SpecialExercise;
