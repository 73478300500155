/** @jsx jsx */
import { Message, jsx, Link, Spinner, Text } from "theme-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import useMyBoolean from "sites-common/hooks/useMyBoolean";
import ConfirmButton from "gatsby-plugin-hfn-profile/components/ConfirmButton";

import {
  Pivot,
  PivotItem,
  Label,
  DefaultButton,
  ActionButton,
  IconButton,
} from "office-ui-fabric-react";
import DynamicForm, {
  DynamicField,
} from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import { noop } from "sites-common/utils/lodash";
import {
  doFormatShortDate,
  formatSys2OdooDate,
} from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
import {
  Table,
  TableCell,
  TableRow,
} from "sites-common/components/ui/DivTable";
import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";
import copyKeys from "sites-common/utils/copyKeys";
import UploadFile from "../../UploadFile";
import config from "./config";

const today = formatSys2OdooDate(new Date());

const {
  formFields,
  cachingKey,
  collection,
  firestorePath,
  initialQuery,
  copyFromProfile,
} = config;

const dfield = formFields.filter((r) => r.name === "sitting_date")[0];

const onDiscard = noop;

function NewRecord() {
  const firestore = useFirestore();
  const [vers, setVers] = useState(false);
  const { srcmProfile = {} } = useAuth();

  const { abhyasiId, userFirebaseUid: uid } = srcmProfile;
  const indexData = useMemo(
    () => copyKeys(srcmProfile, copyFromProfile),
    [srcmProfile]
  );

  const [d1, setD1] = useState(today);

  const [save, { setFalse: setSave, setTrue: setUnsave }] = useMyBoolean(false);

  const onNewAdd = useCallback(
    (payload, successCb, failureCb) => {
      return firestore
        .add(collection, {
          ...indexData,
          ...payload,
          abhyasi_id: abhyasiId,
          uid,
        })
        .then((docRef) => {
          successCb(docRef.id);
          setUnsave();
          setVers(docRef.id);
        })
        .catch((e) => failureCb(e.toString()));
    },
    [abhyasiId, uid, firestore, setVers, indexData, setUnsave]
  );

  return (
    <div sx={{ p: 2 }}>
      <Text variant="title">
        Enter your observations during the sitting in any language
      </Text>
      <div sx={{ backgroundColor: "muted", p: 2, pb: 3 }}>
        {!save && (
          <DynamicForm
            key={vers}
            formFields={formFields}
            defaultValues={{ sitting_date: today, abhyasi_id: abhyasiId, uid }}
            onSubmit={onNewAdd}
            onDiscard={onDiscard}
          />
        )}
        {save && (
          <Message>
            Your Entry Saved! <br />
            <br />
            <DefaultButton onClick={setSave}>Add Another Note</DefaultButton>
          </Message>
        )}
      </div>

      <div sx={{ textAlign: "center", m: 2 }}> or </div>

      <Text variant="title">
        Upload a picture or scan of your diary note in any language
      </Text>
      <div sx={{ backgroundColor: "muted", p: 2, pb: 3 }}>
        <DynamicField {...dfield} onChange={setD1} value={d1} />
        <Label>Attach Picture *</Label>

        <UploadFile
          docExtradata={{
            ...indexData,
            sitting_date: d1,
            abhyasi_id: abhyasiId,
            uid,
          }}
          dbKey="picture"
          filesPath={`${firestorePath}/artefacts/${uid}`}
          dbPath={collection}
          documentId={vers}
        />
      </div>
    </div>
  );
}

function MyRecords() {
  const { srcmProfile = {} } = useAuth();

  const { userFirebaseUid: uid } = srcmProfile;

  const dbRecords = useSelector((state) =>
    cachingKey in state.firestore.ordered
      ? state.firestore.ordered[cachingKey]
      : undefined
  );
  const firestore = useFirestore();
  const [limit, setLimit] = useState(10);

  // const onSave = useCallback(
  //   (
  //     op,
  //     pk_key,
  //     pk_val,
  //     new_data,
  //     old_data,
  //     success_callback,
  //     failure_callback
  //   ) => {
  //     return firestore
  //       .set(`${collection(uid)}/${pk_val}`, new_data)
  //       .then(success_callback)
  //       .catch((e) => failure_callback(e.toString()));
  //   },
  //   [firestore, uid]
  // );

  useEffect(() => {
    const listenerSettings = {
      ...initialQuery({ uid }),
      storeAs: cachingKey,
      limit,
    };
    firestore.setListener(listenerSettings);
    return function cleanup() {
      firestore.unsetListener(listenerSettings);
    };
  }, [firestore, uid, limit]);

  const showMore = dbRecords ? dbRecords.length === limit : false;
  const incr10 = useCallback(() => setLimit((a) => a + 10), []);
  const onDelete = useCallback(
    (r) => {
      firestore.delete(`${collection}/${r.id}`);
    },
    [firestore]
  );
  return (
    <div>
      {dbRecords === undefined && <Spinner />}
      {dbRecords !== undefined && (
        <div>
          {dbRecords.length === 0 && (
            <div sx={{ textAlign: "center", m: 2 }}>
              <Text variant="description"> No Entries Available </Text>
            </div>
          )}
          {dbRecords.length > 0 && (
            <div>
              <Table style={{ width: "100%" }}>
                <tbody>
                  {dbRecords.map((r) => {
                    return (
                      <TableRow key={r.id}>
                        <TableCell>
                          {doFormatShortDate(r.sitting_date)}
                        </TableCell>
                        <TableCell>
                          {!!r.picture && (
                            <div>
                              <Text variant="description">Uploaded File</Text>{" "}
                              <Link
                                target="_blank"
                                href={r.picture.downloadURL}
                              >
                                {" "}
                                {r.picture.fileName}
                              </Link>
                            </div>
                          )}
                          {!r.picture && r.notes}
                        </TableCell>
                        <TableCell>
                          {!r.picture && (
                            <ConfirmButton
                              title={`Deleting note on ${doFormatShortDate(
                                r.sitting_date
                              )}`}
                              onClick={() => onDelete(r)}
                            >
                              <IconButton iconProps={{ iconName: "Trash" }} />
                            </ConfirmButton>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </tbody>
              </Table>
              {showMore && (
                <ActionButton text="show more entries ..." onClick={incr10} />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function RecordsApp() {
  return (
    <div>
      <div sx={{ textAlign: "center", m: 2 }}>
        <Text variant="header">Preceptors Special Sittings - Jan 2022</Text>
        <br />
        <Text variant="subtitle">
          Tuesdays and Fridays at 5:30am local time
        </Text>
        <br />
        <br />
        <Text sx={{ fontWeight: 600, color: "#444", fontSize: "0.7em" }}>
          JOURNAL SHARED WITH REVERED DAAJI
        </Text>
        <hr />
      </div>

      <div>
        <Pivot sx={{ mb: 3 }}>
          <PivotItem headerText="Record New Entry" itemIcon="Edit">
            {false && <NewRecord />}
            <div sx={{ p: 4, bg: "beige", m: 2 }}>
              Special Sittings have ended and accordingly the form for recording
              the entries has been disabled. You can view your recorded entries
              from &quot;My Journal Entries&quot; tab.
            </div>
          </PivotItem>
          <PivotItem headerText="My Journal Entries" itemIcon="ThumbnailView">
            <MyRecords />
          </PivotItem>
        </Pivot>
      </div>
    </div>
  );
}

export default RecordsApp;
