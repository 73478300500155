import { formatSys2OdooDate } from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";

const today = formatSys2OdooDate(new Date());

const formFields = [
  {
    name: "abhyasi_id",
    label: "Abhyasi Id",
    hide: true,
    type: "text",
    props: { readOnly: true, required: true },
  },
  {
    name: "sitting_date",
    label: "Sitting Date",
    type: "date",
    props: { required: true, minDate: "2022-01-10", maxDate: today },
  },
  {
    name: "notes",
    label: "Notes",
    type: "text",
    props: { multiline: true, rows: 6, required: true },
  },
];
const initialQuery = ({ uid }) => ({
  collection: "PreceptorSpecialSittings2022",
  where: ["uid", "==", uid],
  orderBy: [["sitting_date", "desc"]],
});

const config = {
  formFields,
  collection: "PreceptorSpecialSittings2022",
  firestorePath: "preceptorspecialsittings2022",
  initialQuery,
  cachingKey: "spjournal",
  copyFromProfile: ["name", "city", "state", "country"],
};
export default config;
