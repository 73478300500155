/** @jsx jsx */
import { Message, jsx, Spinner } from "theme-ui";
import { useState, useCallback } from "react";
import PropTypes from "prop-types";
// import { useSelector } from "react-redux";
import { useFirebase } from "react-redux-firebase";
import { v4 as uuidv4 } from "uuid";

import { useDropzone } from "react-dropzone";
import { DefaultButton } from "office-ui-fabric-react";

function MyDropzone({ onDrop }) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <div
          sx={{
            height: "75px",
            cursor: "pointer",
            width: "100%",
            bg: "secondary",
            color: "background",
            textAlign: "center",
          }}
        >
          <br />
          Drag and drop files here or click to select
        </div>
      )}
    </div>
  );
}

MyDropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
};

// Path within Database for metadata (also used for file Storage path)

function Uploader({ dbKey, filesPath, dbPath, docExtradata }) {
  const firebase = useFirebase();
  //   const uploadedFiles = useSelector(
  //     ({ firebase: { data } }) => data[filesPath]
  //   );
  const [uploaded, setUploaded] = useState(0);

  const onFilesDrop = useCallback(
    (files) => {
      setUploaded(1);
      return firebase
        .uploadFiles(filesPath, files, dbPath, {
          metadataFactory: (uploadRes, firebase1, metadata, downloadURL) => {
            const {
              metadata: { name, fullPath },
            } = uploadRes;
            return {
              [dbKey]: { downloadURL, fullPath, fileName: name },
              ...docExtradata,
            };
          },

          documentId: uuidv4(),
        })
        .then(() => {
          setUploaded(2);
        });
    },
    [dbKey, dbPath, docExtradata, filesPath, firebase]
  );

  //   const onFileDelete = useCallback(
  //     (file, key) => {
  //       return firebase.deleteFile(file.fullPath, `${filesPath}/${key}`);
  //     },
  //     [filesPath]
  //   );

  const setUploadedFalse = useCallback(() => {
    setUploaded(0);
  }, [setUploaded]);

  return (
    <div>
      {uploaded === 0 && <MyDropzone onDrop={onFilesDrop} />}
      {uploaded === 1 && <Spinner />}
      {uploaded === 2 && (
        <Message>
          Upload Successful! &nbsp;&nbsp;&nbsp;&nbsp;
          <DefaultButton onClick={setUploadedFalse}>Add More</DefaultButton>
        </Message>
      )}
      {/* {uploadedFiles && (
        <div>
          <h3>Uploaded file(s):</h3>
          {map(uploadedFiles, (file, key) => (
            <div key={file.name + key}>
              <span>{file.name}</span>
              <button onClick={() => onFileDelete(file, key)}>
                Delete File
              </button>
            </div>
          ))}
        </div>
      )} */}
    </div>
  );
}

Uploader.propTypes = {
  dbKey: PropTypes.string.isRequired,
  filesPath: PropTypes.string.isRequired,
  dbPath: PropTypes.string.isRequired,
  docExtradata: PropTypes.shape({}).isRequired,
};

export default Uploader;
